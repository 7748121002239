import { title } from "../../../nextjs-material-kit.js";
import { container } from "../../../nextjs-material-kit.js";

const productStyle = (theme) => ({
  section: {
    padding: "0 0",
    textAlign: "center",
  },
  productSection: {
    ...container(theme),
  },
  productSectionContainer: {
    alignItems: 'center',
  },
  max_width: {
    maxWidth: "80%",
    position: "relative",
    top: "-40px",
  },
  title: {
    textDecoration: "none",
    fontFamily: "var(--font-brandon-grotesque)",
    fontWeight: 900,
    fontSize: "6.5em",
  },
  container: {
  },
  description: {
    color: "#999",
  },
  customHeading: {
    fontFamily: "var(--font-brandon-grotesque)",
    fontWeight: 900,
    color: "#095a70",
    fontSize: "60px",
    marginTop: "0px !important",
  },
  primaryColor: {
    backgroundColor: "#095a70",
    marginTop: "15px",
    height: "30px",
    fontFamily: "var(--font-brandon-grotesque)",
    fontWeight: 700,
    textTransform: "none",
    [theme.breakpoints.down('md')]: {
      borderRadius: "8px",
      height: "42px",
      fontSize: "18px",
    },
  },
  leftAlign: {
    textAlign: "left",
  },
});

export default productStyle;
