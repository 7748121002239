// See also _custom.scss for global styles inc. .parallaxXl override for e.g. home page.
const parallaxStyle = (theme) => ({
  parallax: {
    overflow: "hidden",
    position: "relative",
    backgroundPosition: "center top",
    backgroundSize: "cover",
    margin: "0",
    padding: "0",
    border: "0",
    display: "flex",
    alignItems: "center",
    height: "21vw",
    maxHeight: "376px",
    minHeight: "115px",
  },
  parallaxForums: {
    minHeight: "225px",
    height: "auto",
    [theme.breakpoints.down("md")]: {
      minHeight: "300px",
      height: "auto",
    },
    [theme.breakpoints.up('lg')]: {
      minHeight: "245px",
    },
    [theme.breakpoints.up('xl')]: {
      minHeight: "260px",
    }
  },
  parallaxXl: {
    minHeight: "450px",
    height: "auto",
  },
  parallaxImg: {
    objectFit: "contain",
    objectPosition: "center",
    width: "100%",
    height: "auto",
    minHeight: "115px",
  },
  parallaxImgForums: {
    objectFit: "cover",
    height: "100%",
    width: "100%",
    [theme.breakpoints.down("md")]: {
      objectFit: "cover",
      height: "100%",  
    }   
  },
  parallaxImgXl: {
    objectFit: "cover",
    height: "100%",
  },
  parallaxBg: {
    position: "absolute",
    width: "100vw",
    height: "100%",
    textAlign: "center",
  },
  parallaxBgForums: {
    position: "absolute",
    width: "100vw",
    textAlign: "center",
  },
  filter: {
    "&:before": {
      background: "rgba(0, 0, 0, 0.1)",
    },
    "&:after,&:before": {
      position: "absolute",
      zIndex: "1",
      width: "100%",
      height: "100%",
      display: "block",
      left: "0",
      top: "0",
      content: "''",
    },
  },
});

export default parallaxStyle;
