import React from "react";
import { makeStyles } from "tss-react/mui";

// @mui/icons-material
import Chat from "@mui/icons-material/Chat";
import VerifiedUser from "@mui/icons-material/VerifiedUser";
import Fingerprint from "@mui/icons-material/Fingerprint";
// core components
import GridContainer from "../../components/Grid/GridContainer.js";
import GridItem from "../../components/Grid/GridItem.js";
import Button from "../../components/CustomButtons/Button.js";
import welcomeImg from "../../public/img/welcome_img.png";

import styles from "../../styles/jss/nextjs-material-kit/pages/landingPageSections/pathAheadStyle.js";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import { updateQueryString } from "../../util/url";

const useStyles = makeStyles()(styles);

export default function ProductSection({ handleRegisterClick }) {
  const router = useRouter();
  const { classes, cx } = useStyles();
  return (
    <div className={cx(classes.section, "aassaaasasa", classes.productSection)}>
      <GridContainer className={cx(classes.productSectionContainer)}>
        <GridItem xs={12} sm={12} md={7}>
          <Image
            src={welcomeImg}
            alt=""
            style={{ width: "80%", height: "auto" }}
            className={cx(classes.max_width, "welcomeImg")}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={5} className={classes.leftAlign}>
          <h1
            className={cx(
              classes.title,
              classes.leftAlign,
              classes.customHeading
            )}
          >
            Get Registered!
          </h1>
          <p style={{ fontSize: "18px" }}>
            Receive notifications about the latest apprenticeships, university
            events and networking opportunities via email.
          </p>
          <Link
            href={updateQueryString(router.asPath, "register", null)}
            shallow={true}
          >
            <Button
              style={{ fontSize: "16px", boxShadow: "none" }}
              className={cx(classes.primaryColor, "button")}
              size="lg"
              color="info"
            >
              Register
            </Button>
          </Link>
        </GridItem>
      </GridContainer>
    </div>
  );
}
