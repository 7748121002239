import React from "react";
import { makeStyles } from 'tss-react/mui';

// @mui/icons-material

// core components
import GridContainer from "../../components/Grid/GridContainer.js";
import GridItem from "../../components/Grid/GridItem.js";
import Button from "../../components/CustomButtons/Button.js";
import Card from "../../components/Card/Card.js";
import CardBody from "../../components/Card/CardBody.js";
import CardFooter from "../../components/Card/CardFooter.js";
import styles from "../../styles/jss/nextjs-material-kit/pages/landingPageSections/GetOnBoardStyle.js";
import Grid from "@mui/material/Grid";
import Link from "next/link";
import PWC from "../../public/img/faces/pwc.png";
import IBM from "../../public/img/faces/IBM.png";
import NHS from "../../public/img/faces/NHS.png";
import Exeter from "../../public/img/faces/Exeter.png";
import JLR from "../../public/img/faces/JLR.png";
import QA from "../../public/img/faces/QA.png";
import Image from "next/image";

const useStyles = makeStyles()(styles);

export default function GetOnBoard() {
  const { classes, cx } = useStyles();
  const imageClasses = cx(classes.imgFluid);
  return (
    <div className={classes.container}>
      <Grid container alignItems="center">
        <Grid item
          className={cx(classes.card, classes.textContainer)}
          xs={12}
          sm={12}
          md={6}
        >
          <Card plain className={classes.card}>
            <h2 className={classes.cardTitle}>Get on board</h2>
            <CardBody style={{ padding: 0 }}>
              <p className={classes.description}>
                Here’s the thing. Their future is your future. We work with some
                of the biggest brands and organisations and put top opportunities
                in front of young people. You can too with Success at School!
              </p>

              <Link href="/page/contactus">
                <Button
                  className={cx(classes.primaryColor, classes.stdButton)}
                  color="info"
                >
                  Get in touch
                </Button>
              </Link>
            </CardBody>
          </Card>
        </Grid>
        <Grid item container xs={12} sm={12} md={6} className={classes.logos} justify="space-between">
          <Grid item xs={4} sm={4} md={4} className={classes.logoItem}>
            <Image
              src={PWC}
              alt="PWC"
              width={167}
              height={100}
              className={imageClasses}
            />
          </Grid>
          <Grid item xs={4} sm={4} md={4} className={classes.logoItem}>
            <Image
                src={IBM}
                alt="IBM"
                width={100}
                height={100}
                className={imageClasses}
            />
          </Grid>
          <Grid item xs={4} sm={4} md={4} className={classes.logoItem}>
            <Image
                src={NHS}
                alt="NHS"
                width={100}
                height={100}
                className={imageClasses}
            />
          </Grid>

          <Grid item xs={4} sm={4} md={4} className={classes.logoItem}>
            <Image
                src={Exeter}
                alt="Exeter University"
                width={100}
                height={100}
                className={imageClasses}
            />
          </Grid>
          <Grid item xs={4} sm={4} md={4} className={classes.logoItem}>
            <Image
                src={JLR}
                alt="JLR"
                width={100}
                height={100}
                className={imageClasses}
            />
          </Grid>
          <Grid item xs={4} sm={4} md={4} className={classes.logoItem}>
            <Image
                src={QA}
                alt="QA"
                width={100}
                height={100}
                className={imageClasses}
            />
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
