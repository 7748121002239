import React from "react";
import Link from "next/link";
import { makeStyles } from 'tss-react/mui';

// core components
import GridContainer from "../../components/Grid/GridContainer.js";
import GridItem from "../../components/Grid/GridItem.js";
import Button from "../../components/CustomButtons/Button.js";
import Card from "../../components/Card/Card.js";
import CardBody from "../../components/Card/CardBody.js";
import styles from "../../styles/jss/nextjs-material-kit/pages/landingPageSections/needAdviceStyle.js";
import NeedAdviceImg from "../../public/img/faces/need_advice.png";
import resourcesImg from "../../public/img/faces/resources.png";
import Image from "next/image";

const useStyles = makeStyles()(styles);

export default function NeedAdvice() {
  const { classes, cx } = useStyles();
  return (
    <div className={classes.section}>
      <GridContainer className={cx(classes.main, "widthNotAuto")}>
        <GridItem className={classes.noMargin} xs={12} sm={12} md={6}>
          <Card
            plain
            style={{ height: "100%", marginTop: 0 }}
            className={classes.card}
          >
            <GridItem
              xs={12}
              sm={12}
              md={12}
              className={cx(classes.itemGrid, "bgImg")}
            >
              <Image
                  src={NeedAdviceImg}
                  alt="..."
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    objectPosition: "top",
                  }}
              />
            </GridItem>
          </Card>
        </GridItem>
        <GridItem className={classes.noMargin} xs={12} sm={12} md={6}>
          <Card plain className={classes.cardNeedAdvice}>
            <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
              <img
                src="/img/faces/need_advice_icon.png"
                alt="...."
                width="200"
              />
            </GridItem>
            <h2 className={classes.cardTitle}>Need advice?</h2>
            <CardBody className={classes.cardBody}>
              <p className={classes.description}>
                Want to create your first CV or looking for tips to nail that
                exam revision? Take a look at our articles info on study and
                work.
              </p>
              <Button
                className={cx(classes.primaryColor, classes.stdBtn, 'button')}
                color="info"
                component={Link}
                href="/advice"
              >
                Get advice
              </Button>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      <GridContainer className={cx(classes.main, "widthNotAuto")}>
        <GridItem
          xs={12}
          sm={12}
          md={6}
          className={cx(
            classes.noMargin,
            classes.blueBg,
            classes.first
          )}
        >
          <Card plain className={classes.cardResources}>
            <GridItem
              xs={12}
              sm={12}
              md={6}
              className={cx(classes.itemGrid)}
            >
              <img src="/img/faces/resources_icon.png" alt="..." width="200" />
            </GridItem>
            <h2 className={classes.cardTitle}>Resources</h2>
            <CardBody className={classes.cardBody}>
              <p className={classes.description}>
                Students need good career advice like never before.
                Our posters, guides and lesson plans are used by over 2,000 schools.
                Get yours today!
              </p>
              <Button
                className={cx(classes.primaryColor, classes.stdBtn, 'button')}
                color="info"
                component="a"
                href="https://shop.successatschool.org"
                rel="noopener external"
                target="_blank"
              >
                Find out more
              </Button>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem
          className={cx(classes.noMargin, classes.second)}
          xs={12}
          sm={12}
          md={6}
        >
          <Card
            plain
            className="ttttt"
            style={{
              height: "100%",
              marginTop: "0px",
              marginBottom: "0px",
            }}
          >
            <GridItem
              xs={12}
              sm={12}
              md={12}
              className={cx(classes.itemGrid, "bgImg")}
            >
              <Image
                  src={resourcesImg}
                  alt="..."
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    objectPosition: "top",
                  }}
              />
            </GridItem>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
