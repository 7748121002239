import { cardTitle, title } from "../../../nextjs-material-kit.js";
import imagesStyle from "../../imagesStyles.js";
import { container } from "../../../nextjs-material-kit.js";

const teamStyle = (theme) => ({
  section: {
    padding: "70px 0",
    textAlign: "center",
  },
  container: {
    ...container(theme),
    justifyContent: "center",
    paddingTop: "20px",
    paddingBottom: "20px",
    [theme.breakpoints.down('lg')]: {
      maxWidth: "100%",
      paddingLeft: '30px',
      paddingRight: '30px',
      margin: 'auto',
    },
  },
  primaryColor: {
    backgroundColor: "#095a70",
    marginLeft: "-15px",
    marginTop: "15px",
  },
  title: {
    ...title,
    marginBottom: "1rem",
    marginTop: "30px",
    minHeight: "32px",
    textDecoration: "none",
  },
  ...imagesStyle,
  itemGrid: {
    height: "100%",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
  card: {
    padding: 0,
    [theme.breakpoints.down('md')]: {
      margin: "0 0 20px 0",
    },
  },
  cardTitle: {
    margin: 0,
    fontFamily: "var(--font-brandon-grotesque)",
    fontWeight: 900,
    textAlign: "left",
    color: "#095A70",
    fontSize: "60px",
    lineHeight: "0.7",
    padding: "10px 0",
  },
  noMargin: {
    padding: "0px !important",
  },
  smallTitle: {
    color: "#6c757d",
  },
  description: {
    marginTop: "15px",
    color: "#000",
    fontFamily: "var(--font-brandon-grotesque)",
    fontSize: "16px",
    lineHeight: "18px",
    textAlign: "left",
    [theme.breakpoints.down('md')]: {
      fontSize: "18px",
      lineHeight: "22px",
    },
  },
  justifyCenter: {
    justifyContent: "center !important",
  },
  socials: {
    marginTop: "0",
    width: "100%",
    transform: "none",
    left: "0",
    top: "0",
    height: "100%",
    lineHeight: "41px",
    fontSize: "20px",
    color: "#999",
  },
  margin5: {
    margin: "5px",
  },
  imgFluid: {
    alignSelf: "center",
    margin: "0 auto",
    maxWidth: "100%",
    maxHeight: "100px",
  },
  floatLeft: {
    float: "left",
  },
  boxShadow: {
    boxShadow: "none",
    marginBottom: "10px !important",
    marginTop: "10px !important",
  },
  logos: {
    width: "100%",
    padding: "10px",
    justifyContent: "space-between",
    [theme.breakpoints.down('lg')]: {
      justifyContent: "space-evenly",
      padding: "0 0 20px 0",
      margin: "auto !important",
    },
  },
  logoItem: {
    maxHeight: "100%",
    maxWidth: "130px",
    display: "flex",
    margin: "9px 0",
    padding: 0,
    [theme.breakpoints.down('lg')]: {
      marginTop: "10px",
      maxWidth: "33.333333%",
      paddingLeft: "!5px",
      paddingRight: "15px",
    },
  },
  stdButton: {
    marginTop: "13px",
    height: "35px",
    fontFamily: "var(--font-brandon-grotesque)",
    fontWeight: 700,
    fontSize: "16px",
    textTransform: "none",
    boxShadow: "none",
    minWidth: "150px",
    padding: 0,
    borderRadius: "6px",
    margin: "5px 0 0 0",
    [theme.breakpoints.down('md')]: {
      height: "45px",
      fontSize: "18px",
      padding: "0 20px",
    },
  },
  textContainer: {
    padding: "0 20px 0 0",
    [theme.breakpoints.down('md')]: {
      padding: 0,
    },
  },
});

export default teamStyle;
