import { container } from "../../../nextjs-material-kit.js";

const productStyle = (theme) => ({
  section: {
    paddingLeft: "0",
    paddingRight: "0",
    paddingTop: "28px",
    textAlign: "center",
  },
  p: {
    color: "#000",
    [theme.breakpoints.down('md')]: {
      fontSize: "0.9rem",
    },
  },
  max_width: {
    maxWidth: "60%",
    position: "absolute",
    top: "0",
    left: "50%",
    transform: "translate(-25%, -105px)",
  },
  title: {
    textDecoration: "none",
    fontFamily: "var(--font-brandon-grotesque)",
    fontWeight: 900,
    fontSize: "6.5em",
  },
  container: {
    ...container(theme),
  },
  description: {
    color: "#999",
  },
  customHeading: {
    marginTop: "6px",
    fontFamily: "var(--font-brandon-grotesque)",
    fontWeight: 900,
    color: "#095a70",
    fontSize: "60px",
    [theme.breakpoints.down('md')]: {
      fontSize: "52px",
    },
  },
  primaryColor: {
    backgroundColor: "#095a70",
  },
});

export default productStyle;
