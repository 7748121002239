import React from "react";
import Link from "next/link";
// @material-ui/core components
import { makeStyles } from 'tss-react/mui';

import GridContainer from "../../components/Grid/GridContainer.js";
import GridItem from "../../components/Grid/GridItem.js";
import Card from "../../components/Card/Card.js";
import styles from "../../styles/jss/nextjs-material-kit/pages/landingPageSections/WeGotYouStyle.js";
import Careers from "../../public/img/faces/careers.png";
import Employers from "../../public/img/faces/employers.png";
import Universities from "../../public/img/faces/universities.png";
import Image from "next/image";

const useStyles = makeStyles()(styles);

export default function WeGotYou() {
  const { classes, cx } = useStyles();
  return (
    <div className={cx(classes.container)}>
      <GridContainer style={{margin: "auto"}}>
        <GridItem xs={12} sm={12} md={12}>
          <h1 className={cx(classes.title, classes.customHeading)}>
            We’ve got you!
          </h1>
        </GridItem>
        <GridContainer className={classes.boxContainer}>
          <GridItem xs={12} sm={12} md={4} className={classes.cardContainer}>
            <Link href="/career-zones">
              <Card
                className={cx(classes.jobCard, classes.green, "gotyou", "link-card")}
              >
                <Image
                  src={Careers}
                  alt="..."
                  className={classes.imgFluid}
                  style={{width: "100%", height: "auto"}}
                />
                <h4 style={{marginBottom: 0}} className={classes.cardTitle}>
                  Career Zones
                </h4>
                <p className={classes.gotyou}>
                  Explore different careers, how to get there and what to study.
                </p>
              </Card>
            </Link>
          </GridItem>

          <GridItem xs={12} sm={12} md={4} className={classes.cardContainer}>
            <Link href="/employers">
              <Card
                className={cx(classes.jobCard, classes.blue, "gotyou", "link-card")}
              >
                <Image
                    src={Employers}
                    alt="..."
                    className={classes.imgFluid}
                    style={{width: "100%", height: "auto"}}
                />
                <h4 style={{marginBottom: 0}} className={classes.cardTitle}>
                  Employers
                </h4>
                <p className={classes.gotyou}>
                  Find out about top employers, what it's like to work for them
                  and what they have to offer you.
                </p>
              </Card>
            </Link>
          </GridItem>

          <GridItem xs={12} sm={12} md={4} className={classes.cardContainer}>
            <Link href="/universities">
              <Card
                className={cx(classes.jobCard, classes.purple, "gotyou", "link-card")}
              >
                <Image
                    src={Universities}
                    alt="..."
                    className={classes.imgFluid}
                    style={{width: "100%", height: "auto"}}
                />
                <h4 style={{marginBottom: 0}} className={classes.cardTitle}>
                  Universities
                </h4>
                <p className={classes.gotyou}>
                  Explore life on campus and get updates on the latest courses,
                  events and open days.
                </p>
              </Card>
            </Link>
          </GridItem>
        </GridContainer>
      </GridContainer>
    </div>
  );
}
