import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
import { makeStyles } from 'tss-react/mui';
// @mui/icons-material

// core components
import styles from "../../styles/jss/nextjs-material-kit/components/cardFooterStyle.js";

const useStyles = makeStyles()(styles);

export default function CardFooter(props) {
  const { classes, cx } = useStyles();
  const { className, children, ...rest } = props;
  const cardFooterClasses = cx({
    [classes.cardFooter]: true,
    [className]: className !== undefined,
  });
  return (
    <div className={cardFooterClasses} {...rest}>
      {children}
    </div>
  );
}

CardFooter.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};
