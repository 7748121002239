import React from "react";
import { makeStyles } from 'tss-react/mui';

// @mui/icons-material
import Chat from "@mui/icons-material/Chat";
import VerifiedUser from "@mui/icons-material/VerifiedUser";
import Fingerprint from "@mui/icons-material/Fingerprint";
// core components
import GridContainer from "../../components/Grid/GridContainer.js";
import GridItem from "../../components/Grid/GridItem.js";
import InfoArea from "../../components/InfoArea/InfoArea.js";
import Button from "../../components/CustomButtons/Button.js";

import styles from "../../styles/jss/nextjs-material-kit/pages/landingPageSections/topJobsStyle.js";

const useStyles = makeStyles()(styles);

export default function TopJobs() {
  const { classes, cx } = useStyles();
  return (
    <div className={[classes.section, "aassaaasasa"].join(" ")}>
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <h1 className={cx(classes.title, classes.customHeading)}>
              Top Jobs
            </h1>
            <p style={{ fontSize: "15px" }} className={cx(classes.p)}>
              Here's a quick glimpse of the latest opportunities...
            </p>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
