import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
import { makeStyles } from "tss-react/mui";
import Image from "next/image";

// core components
import styles from "../../styles/jss/nextjs-material-kit/components/parallaxStyle.js";

const useStyles = makeStyles()(styles);

/**
 * This is currently just a confusingly named header! The transform on scroll stuff seemed to be
 * non-functional in the prototype, so to avoid possible performance impact it's now deleted.
 */
export default function Parallax(props) {
  const { filter, className, children, style, image, size, forums } = props;
  const { classes, cx } = useStyles();
  const parallaxClasses = cx({
    [classes.parallax]: true,
    [classes.filter]: filter,
    [classes.parallaxXl]: size === "lg",
    [classes.parallaxForums]: forums,
  });

  return (
    <div
      className={cx(parallaxClasses, "parallaxx")}
      style={{
        ...style,
      }}
    >
      <div className={cx(classes.parallaxBg, {[classes.parallaxBgForums]: forums,})}>
        <Image
          src={image}
          alt=""
          placeholder={typeof image === "string" ? "empty" : "blur"}
          loading="eager"
          priority={true}
          width={1650}
          height={413}
          sizes="100vw"
          className={cx(classes.parallaxImg, {
            [classes.parallaxImgXl]: size === "lg",
            [classes.parallaxImgForums]: forums,
          })}
        />
      </div>
      {children}
    </div>
  );
}

Parallax.propTypes = {
  className: PropTypes.string,
  filter: PropTypes.bool,
  children: PropTypes.node,
  style: PropTypes.object,
  image: PropTypes.string,
};
