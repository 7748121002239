import {primaryColor, secondaryColor, tertiaryColor, title} from "../../../nextjs-material-kit.js";
import { container } from "../../../nextjs-material-kit.js";

const productStyle = (theme) => ({
  section: {
    padding: "0 0",
    textAlign: "center",
  },
  cardTitle: {
    fontFamily: "var(--font-brandon-grotesque)",
    fontWeight: 900,
    fontSize: "21px",
    textAlign: "center",
  },
  jobCard: {
    borderRadius: "0px",
    boxShadow: "none",
    color: "#ffffff",
    paddingTop: "0px",
    [theme.breakpoints.down('lg')]: {
      margin: "10px 0",
    },
  },
  gotyou: {
    paddingLeft: "20px",
    paddingRight: "20px",
    fontSize: "1rem",
    textAlign: "center",
    minHeight: "65px",
    lineHeight: "1.2",
    [theme.breakpoints.down('lg')]: {
      minHeight: "100px",
      fontSize: "18px",
    },
    [theme.breakpoints.down('md')]: {
      minHeight: "50px",
    },
  },
  imgFluid: {
    width: "100%",
    [theme.breakpoints.down('md')]: {
      display: "none",
    },
  },
  green: {
    backgroundColor: primaryColor,
  },
  blue: {
    backgroundColor: tertiaryColor,
  },
  purple: {
    backgroundColor: secondaryColor,
  },
  max_width: {
    maxWidth: "60%",
    position: "absolute",
    top: "0",
    left: "50%",
    transform: "translate(-25%, -105px)",
  },
  title: {
    textDecoration: "none",
    fontFamily: "var(--font-brandon-grotesque)",
    fontWeight: 900,
    fontSize: "6.5em",
    textAlign: "center",
  },
  container: {
    maxWidth: "79%",
    margin: "auto !important",
    [theme.breakpoints.down('lg')]: {
      maxWidth: "90%",
    },
    "@media (max-width: 1024px)": {
      maxWidth: "97% !important",
    },
  },
  boxContainer: {
    width: "100%",
    display: "flex",
    margin: "auto",
    justifyContent: "space-between",
  },
  cardContainer: {
    width: "300px",
    minWidth: "300px",
    [theme.breakpoints.down('md')]: {
      padding: 0,
      height: "auto",
      width: "100% !important",
      maxWidth: "100%",
    },

    a: {
      textDecoration: "none",
    },
  },
  description: {
    color: "#999",
  },
  customHeading: {
    fontFamily: "var(--font-brandon-grotesque)",
    fontWeight: 900,
    color: "#095a70",
    fontSize: "60px",
    marginTop: 0,
  },

  primaryColor: {
    backgroundColor: "#095a70",
  },
});

export default productStyle;
