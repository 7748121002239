import { cardTitle, title } from "../../../nextjs-material-kit.js";
import imagesStyle from "../../imagesStyles.js";

const teamStyle = (theme) => ({
  main: {
    maxHeight: "350px",
    [theme.breakpoints.down('md')]: {
      maxHeight: "unset !important",
    },
  },
  section: {
    padding: "70px 0px",
    textAlign: "center",
  },
  title: {
    ...title,
    marginBottom: "1rem",
    marginTop: "30px",
    minHeight: "32px",
    textDecoration: "none",
  },
  ...imagesStyle,
  itemGrid: {
    height: "100%",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    padding: 0,
    [theme.breakpoints.down('lg')]: {
      "& img": {
        display: "none",
      },
    },
  },
  card: {
    [theme.breakpoints.down('md')]: {
      margin: 0,
    },
  },
  cardTitle: {
    ...cardTitle,
    fontFamily: "var(--font-brandon-grotesque)",
    fontWeight: 900,
    textAlign: "left",
    color: "#095A70",
    fontSize: "60px",
    lineHeight: 1,
    paddingTop: "10px",
    margin: "0.625rem 0 !important",
    [theme.breakpoints.down('md')]: {
      margin: 0,
    },
  },
  cardBody: {
    padding: 0,
  },
  noMargin: {
    padding: "0px !important",
    height: "350px",
    [theme.breakpoints.down('md')]: {
      height: "unset !important",
    },
  },
  smallTitle: {
    color: "#6c757d",
  },
  description: {
    color: "#000",
    fontFamily: "var(--font-brandon-grotesque)",
    fontSize: "16px",
    fontWeight: "100 !important",
    textAlign: "left",
    lineHeight: "18px",
    [theme.breakpoints.down('md')]: {
      lineHeight: "22px",
      marginBottom: "20px !important",
      fontSize: "18px",
    },
  },
  justifyCenter: {
    justifyContent: "center !important",
  },
  socials: {
    marginTop: "0",
    width: "100%",
    transform: "none",
    left: "0",
    top: "0",
    height: "100%",
    lineHeight: "41px",
    fontSize: "20px",
    color: "#999",
  },
  margin5: {
    margin: "5px",
  },
  blueBg: {
    backgroundColor: "#eaf6fe",
  },
  stdBtn: {
    backgroundColor: "#095a70",
    float: "left",
    textTransform: "inherit !important",
    fontFamily: "var(--font-brandon-grotesque) !important",
    fontWeight: 700,
    fontSize: "16px !important",
    borderRadius: "5px",
    minWidth: "140px",
    boxShadow: "none",
    height: "30px",
    [theme.breakpoints.down('md')]: {
      height: "45px",
      fontSize: "18px !important",
      marginBottom: "40px",
    },
  },
  first: {
    order: 1,
    [theme.breakpoints.down('md')]: {
      order: 2,
    },
    rightMargin: "17%",
  },
  second: {
    order: 2,
    [theme.breakpoints.down('md')]: {
      order: 1,
    },
  },
  cardNeedAdvice: {
    marginTop: "30px",
    marginBottom: 0,
    paddingRight: "25%",
    paddingLeft: "10%",
    [theme.breakpoints.down("md")]: {
      padding: "5px 30px",
      margin: 0,
      minHeight: "200px",
    },
  },
  cardResources: {
    marginTop: "30px",
    marginBottom: 0,
    paddingLeft: "23.5%",
    paddingRight: "10%",
    [theme.breakpoints.down('md')]: {
      padding: "5px 30px",
      margin: 0,
      minHeight: "200px",
    },
  },
});

export default teamStyle;
